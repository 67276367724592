<template>
  <a-dropdown>
    <!-- <a class="ant-dropdown-link" @click="e => e.preventDefault()">
        Hover me <a-icon type="down" />
      </a> -->
    <a-avatar class="avatar" :size="40" icon="user"  @click="(e) => e.preventDefault()" />
    <a-menu slot="overlay">
      <a-menu-item>
        <div  @click="Loginout">退出登陆</div>
      </a-menu-item>
     
    </a-menu>
  </a-dropdown>
</template>
<script>
import {Logout} from '../api/index'
export default {
  data() {
    return {
      user:''
    };
  },
  created(){
   this.user=localStorage.removeItem("user")
   console.log('====================================');
   console.log(this.user);
   console.log('====================================');
  },
  methods: {
    Loginout(){
        Logout().then(()=>{
            this.$router.push('/login')

        })
    }
  },
};
</script>
<style scoped>
.avatar{
    align-self: center;
    margin-right: 50px;
}
</style>
  