<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="top_header">
      <a-button class="add_button" @click="show_add_modal" type="primary"
        >添加</a-button
      >
    </div>
    <div class="top_header1">
      <div
        :style="
          menu_index == 'type' ? 'color: black;background-color: #b5b5b5;' : ''
        "
        @click="change_tur_header('type')"
        class="top_title"
      >
        大分类
      </div>
      <div
        :style="
          menu_index == 'dolat' ? 'color: black;background-color: #b5b5b5;' : ''
        "
        @click="change_tur_header('dolat')"
        class="top_title"
      >
        国家
      </div>
      <div
        :style="
          menu_index == 'tur' ? 'color: black;background-color: #b5b5b5;' : ''
        "
        @click="change_tur_header('tur')"
        class="top_title"
      >
        分类
      </div>
      <div
        :style="
          menu_index == 'yil' ? 'color: black;background-color: #b5b5b5;' : ''
        "
        @click="change_tur_header('yil')"
        class="top_title"
      >
        年
      </div>
    </div>
    <a-table :columns="columns" :data-source="item_list">
      <div class="caozuo" slot="caozuo" slot-scope="record">
        <a-button
          @click="show_edit_modal(record)"
          size="small"
          type="primary"
          style="margin-right: 30px"
        >
          编辑
        </a-button>
        <a-popconfirm
          title="确定删除？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="delet_book_item(record)"
          @cancel="delet_cancel"
        >
          <a-button
            @click="show_delet_modal(record.id)"
            size="small"
            type="danger"
          >
            删除
          </a-button>
        </a-popconfirm>
      </div>

      <div slot="poster" slot-scope="text, record">
        <img class="imges" :src="text" />
      </div>
    </a-table>
    <a-modal
      title="添加"
      :visible="add_visible"
      @ok="add_cate_ok"
      @cancel="add_cate_ok_can"
      :width="820"
    >
      <a-form-model :model="add_cate_from">
        <a-form-model-item label="输入分类标题">
          <a-input style="width: 300px" v-model="add_cate_from.name" />
        </a-form-model-item>
        <a-form-model-item label="输入分类略图">
          <a-input style="width: 300px" v-model="add_cate_from.poster" />
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="selfUpload"
            @change="handleChange"
          >
            <img style="width: 180px" v-if="add_cate_from.poster" :src="add_cate_from.poster" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="编辑"
      :visible="edit_visible"
      @ok="edit_cate_ok"
      @cancel="edit_cate_ok_can"
      :width="820"
    >
      <a-form-model :model="edit_cate_from">
        <a-form-model-item label="输入分类标题">
          <a-input style="width: 300px" v-model="edit_cate_from.name" />
        </a-form-model-item>
        <a-form-model-item label="输入分类略图">
          <a-input style="width: 300px" v-model="edit_cate_from.poster" />
          <a-upload
            name="file"
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :customRequest="bian_selfUpload"
            @change="handleChange"
          >
            <img style="width: 180px" v-if="edit_cate_from.poster" :src="edit_cate_from.poster" />
            <div v-else>
              <a-icon type="plus" />
              <div class="ant-upload-text">Upload</div>
            </div>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}
import {
  getCates,
  deleteCates,
  updateCates,
  addCate,
  getCateItem,
  getTypeItem,
  deleteTypeItem,
  imageUpload
} from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },
  {
    title: "分类标题",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "分类略图",
    dataIndex: "poster",
    align: "center",
    scopedSlots: { customRender: "poster" },
  },

  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];
export default {
  data() {
    return {
      columns,
      cate_item_id: "",
      add_visible: false,
      edit_visible: false,
      cate_list: {},
      add_cate_from: {
        name: "",
        poster: "",
      },
      edit_cate_from: {},
      menu_index: "type",
      item_list: [],
    };
  },
  mounted() {
    
    getCates().then((res) => {
      this.cate_list = res.data.data;
      this.item_list = res.data.data.type;
    });
  },
  methods: {
    bian_selfUpload({ action, file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        
        this.edit_cate_from.poster=res.data.data.url
      })
    },
    selfUpload({ action, file, onSuccess, onError, onProgress }) {
      const formData = new FormData();
      let that = this;
      formData.append("file", file);
      imageUpload(formData).then(res=>{
        
        this.add_cate_from.poster=res.data.data.url
      })
    },
    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("You can only upload JPG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("Image must smaller than 2MB!");
      }
      console.log(isJpgOrPng && isLt2M);
      return isJpgOrPng && isLt2M;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    change_tur_header(e) {
      this.menu_index = e;
      switch (e) {
        case "type":
          this.item_list = this.cate_list.type;
          break;
        case "dolat":
          this.item_list = this.cate_list.dolat;
          break;
        case "yil":
          this.item_list = this.cate_list.yil;
          break;
        default:
          this.item_list = this.cate_list.tur;
      }
    },
    edit_cate_ok() {
      updateCates(this.edit_cate_from, this.edit_cate_from.id).then((res) => {
        this.edit_visible = false;
        this.edit_cate_from = {};
        this.get_cate_list();
      });
    },
    edit_cate_ok_can() {
      this.edit_visible = false;
      this.edit_cate_from = {};
    },
    add_cate_ok() {
      this.add_cate_from.type = this.menu_index;
      switch (this.menu_index) {
        case "type":
          this.add_cate_from.type = 0;
          break;
        case "dolat":
          this.add_cate_from.type = 1;
          break;
        case "yil":
          this.add_cate_from.type = 3;
          break;
        default:
          this.add_cate_from.type = 2;
      }
      addCate(this.add_cate_from).then((res) => {
        this.get_cate_list();
        this.add_visible = false;
      });
    },
    add_cate_ok_can() {
      this.add_visible = false;
      this.add_cate_from.title = "";
    },
    status_change(e) {
      console.log(e);
    },
    get_cate_list() {
      getCates().then((res) => {
        this.cate_list = res.data.data.data;
      });
    },
    show_edit_modal(id) {
      this.edit_visible = true;
      if (id.type==0) {
        getTypeItem(id.id).then(res=>{
          this.edit_cate_from = res.data.data;
        })
      }else{
        getCateItem(id.id).then((res) => {
        this.edit_cate_from = res.data.data;
      });
      }
      
    },
    delet_book_item(item) {
      console.log(item);
      if (item.type==0) {
        deleteTypeItem(item.id).then(res=>{
          this.get_cate_list();
        })
      }else{
    deleteCates(this.cate_item_id).then((res) => {
        this.get_cate_list();
      });
      }
  
    },
    delet_cancel() {},
    show_delet_modal(id) {
      this.cate_item_id = id;
    },
    show_add_modal() {
      this.add_visible = true;
    },
  },
};
</script>
<style scoped>
.imges {
  width: 60px;
  height: 60px;
}
.caozuo {
  align-items: center;
  width: 100%;
  display: flex;
}
.add_button {
  align-self: center;
  margin-right: 50px;
}
.top_header {
  background-color: #f0f2f5;
  width: 100%;
  height: 80px;
  display: flex;
  direction: rtl;
}
.top_header1 {
  width: 60%;
  height: 80px;
  display: flex;
  justify-content: space-around;
}
.top_title {
  width: 60px;
  height: 40px;
  border-radius: 10px;
  text-align: center;
  font-size: 15px;
  align-self: center;
  line-height: 40px;
}
</style>