import Request from "../utls/requeast";



//登陆
export const Login=(param)=>{
    return Request.post('/admin/login',param);
}
export const Logout=()=>{
    return Request.post('/admin/logout');
}
export const init=()=>{
    return Request.post('/admin/user_info');
}


//films
export const getFilmList=  (page,desc)=>{
    console.log('fuck');
    return Request.get('/admin/film_da?page='+page+'&&desc='+desc);
}

export const getFilmItem=(id)=>{
    return Request.get('/admin/film_da/'+id);
}
export const getFilmComponents=(page,param)=>{
    return Request.post('/admin/film_get_components?page='+page,param);
}
export const addFilmItem=(param)=>{
    return Request.post('/admin/film_da',param);
}
export const deleteFilmItem=(id)=>{
    return Request.delete('/admin/film_da/'+id);
}

export const updateFilmData=(param,id)=>{

    return Request.put('/admin/film_da/'+id,param);
}

export const getToplamList=()=>{
    return Request.get('/admin/getToplamList');
}

//stars
export const getStarsList=(page)=>{
    return Request.get('/admin/stars_da?page='+page);
}

export const getStarsItem=(id)=>{
    return Request.get('/admin/stars_da/'+id);
}
export const addStarsItem=(param)=>{
    return Request.post('/admin/stars_da',param);
}
export const deleteStarsItem=(id)=>{
    return Request.delete('/admin/stars_da/'+id);
}

export const updateStarsData=(param,id)=>{

    return Request.put('/admin/stars_da/'+id,param);
}

export const getStarsListData=()=>{
    return Request.get('/admin/get_stars_list');
}


//views

export const getViewList=(page)=>{
    return Request.get('/admin/view_da?page='+page);
}

export const getViewItem=(id)=>{
    return Request.get('/admin/view_da/'+id);
}
export const addViewItem=(param)=>{
    return Request.post('/admin/view_da',param);
}
export const deleteViewItem=(id)=>{
    return Request.delete('/admin/view_da/'+id);
}

export const updateViewData=(param,id)=>{

    return Request.put('/admin/view_da/'+id,param);
}






//types
export const getTypeList=()=>{
    return Request.get('/admin/type_da');
}

export const getTypeItem=(id)=>{
    return Request.get('/admin/type_da/'+id);
}
export const addTypeItem=(param)=>{
    return Request.post('/admin/type_da',param);
}
export const deleteTypeItem=(id)=>{
    return Request.delete('/admin/type_da/'+id);
}

export const updateTypeData=(param,id)=>{

    return Request.put('/admin/type_da/'+id,param);
}

//cates
export const getCates=()=>{
    return Request.get('/admin/cate_da');
}

export const deleteCates=(id)=>{
    return Request.delete('/admin/cate_da/'+id);
}
export const updateCates=(param,id)=>{
    return Request.put('/admin/cate_da/'+id,param);
}

export const addCate=(param)=>{
    return Request.post('/admin/cate_da',param);
}

export const getCateItem=(id)=>{
    return Request.get('/admin/cate_da/'+id);
}


//huifu

export const getHuifu=(page)=>{
    return Request.get('/admin/huifu_da?page='+page);
}

export const deleteHuifu=(id)=>{
    return Request.delete('/admin/huifu_da/'+id);
}
export const updateHuifu=(param,id)=>{
    return Request.put('/admin/huifu_da/'+id,param);
}

export const addHuifu=(param)=>{
    return Request.post('/admin/huifu_da',param);
}

export const getHuifuItem=(id)=>{
    return Request.get('/admin/huifu_da/'+id);
}








//kisims


export const getComItem=(id)=>{
    return Request.get('/admin/com_da/'+id,);
}

export const setComItem=(param)=>{
    return Request.post('/admin/com_da',param);
}


export const updateComItem=(param,id)=>{
    return Request.put('/admin/com_da/'+id,param);
}

export const deleteEComItem=(id)=>{
    return Request.delete('/admin/com_da/'+id);
}





//types
export const getVipList=()=>{
    return Request.get('/admin/vip_cate_da');
}

export const getVipItem=(id)=>{
    return Request.get('/admin/vip_cate_da/'+id);
}
export const addVipItem=(param)=>{
    return Request.post('/admin/vip_cate_da',param);
}
export const deleteVipItem=(id)=>{
    return Request.delete('/admin/vip_cate_da/'+id);
}

export const updateVipData=(param,id)=>{

    return Request.put('/admin/vip_cate_da/'+id,param);
}

export const imageUpload=(param)=>{
    return Request.post('/admin/imageUpload',param);
}



//用户


export const getUsers=(page,param)=>{
    return Request.get('/admin/user_da?page='+page+'&&desc='+param);
}

export const showUsers=(id)=>{
    return Request.get('/admin/user_da/'+id,);
}



export const setUsers=(param,id)=>{
    return Request.put('/admin/user_da/'+id,param);
}

export const setUsersVip=(param)=>{
    return Request.put('/admin/user_vip_update',param);
}

//订单
export const getOrders=(page,param)=>{
    return Request.get('/admin/order_da?page='+page+'&&desc='+param);
}

export const showOrders=(id)=>{
    return Request.get('/admin/order_da/'+id,);
}


export const setOrders=(param,id)=>{
    return Request.put('/admin/order_da/'+id,param);
}


//代理
export const getAgent=(page)=>{
    return Request.get('/admin/agent_da?page='+page);
}

export const showAgent=(id)=>{
    return Request.get('/admin/agent_da/'+id,);
}


export const setAgent=(param,id)=>{
    return Request.put('/admin/agent_da/'+id,param);
}



//代理体现

export const getAgRef=(page)=>{
    return Request.get('/admin/agent_ref_da?page='+page);
}

export const showAgRef=(id)=>{
    return Request.get('/admin/agent_ref_da/'+id,);
}


export const setAgRef=(param,id)=>{
    return Request.put('/admin/agent_ref_da/'+id,param);
}


//设置

export const getSetting=()=>{
    return Request.get('/admin/setting_da');
}

export const setSetting=(param,id)=>{
    return Request.put('/admin/setting_da/'+id,param);
}


//home
export const getHomeData=()=>{
    return Request.get('/admin/home_da');
}

