<template>
  <div>
    <div class="top_header">
      <a-button class="add_button" @click="show_add_modal" type="primary"
        >添加</a-button
      >
    </div>
    <a-table
      :columns="columns"
      :data-source="list_data"
      :pagination="false"
    >
      <div class="caozuo" slot="caozuo" slot-scope="record">
        <a-button
          @click="show_edit_modal(record.id)"
          size="small"
          type="primary"
          style="margin-right: 30px"
        >
          编辑
        </a-button>
        <a-popconfirm
          title="确定删除？"
          ok-text="Yes"
          cancel-text="No"
          @confirm="delet_book_item(record.id)"
          @cancel="delet_cancel"
        >
          <a-button
            size="small"
            type="danger"
          >
            删除
          </a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <a-modal
      title="添加"
      :visible="add_visible"
      @ok="add_ok"
      @cancel="add_can"
      :width="820"
    >
      <a-form-model :model="add_from">
        <a-form-model-item label="会员标题">
          <a-input style="width: 300px" v-model="add_from.name" />
        </a-form-model-item>
        <a-form-model-item label="会员价格">
          <a-input style="width: 300px" v-model="add_from.price" />
        </a-form-model-item>
        <a-form-model-item label="有效时间">
          <a-input style="width: 300px" v-model="add_from.vip_day" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <a-modal
      title="编辑"
      :visible="edit_visible"
      @ok="edit_ok"
      @cancel="edit_can"
      :width="820"
    >
      <a-form-model :model="edit_from">
        <a-form-model-item label="会员标题">
          <a-input style="width: 300px" v-model="edit_from.name" />
        </a-form-model-item>
        <a-form-model-item label="会员价格">
          <a-input style="width: 300px" v-model="edit_from.price" />
        </a-form-model-item>
        <a-form-model-item label="有效时间">
          <a-input style="width: 300px" v-model="edit_from.vip_day" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import {
  getVipList,
  getVipItem,
  addVipItem,
  deleteVipItem,
  updateVipData,
} from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },
  {
    title: "标题",
    dataIndex: "name",
    align: "center",
  },
  {
    title: "价格",
    align: "center",
    dataIndex: "price",
  },
  {
    title: "有效时间",
    align: "center",
    dataIndex: "vip_day",
  },

  {
    title: "操作",
    align: "center",
    scopedSlots: { customRender: "caozuo" },
  },
];
export default {
  data() {
    return {
      columns,
      list_data: [],
      add_visible: false,
      add_from: {
        name: "",
        price: "",
        vip_day:''
      },
      edit_from: {},
      edit_visible: false,
    };
  },
  mounted() {
    getVipList().then((res) => {
      this.list_data = res.data.data;
    });
  },
  methods: {
    delet_book_item(id){
        console.log(id);
        deleteVipItem(id).then(res=>{
            getVipList().then((res) => {
      this.list_data = res.data.data;
    });
        })
    },
    delet_cancel(){},
    show_edit_modal(id) {
      getVipItem(id).then((res) => {
        this.edit_from = res.data.data;
        this.edit_visible = true;
      });
    },
    edit_can() {
      this.edit_visible = false;
      this.edit_from = {};
    },
    edit_ok() {
      updateVipData(this.edit_from, this.edit_from.id).then((res) => {
        getVipList().then((res) => {
          this.list_data = res.data.data;
        });
        this.edit_visible=false
      });
    },
    add_can() {
      this.add_visible = false;
      this.add_from = {
        name: "",
        price: "",
      };
    },
    add_ok() {
      addVipItem(this.add_from).then((res) => {
        getVipList().then((res) => {
          this.list_data = res.data.data;
          this.add_visible = false;
          this.add_from = {
            name: "",
            price: "",
          };
        });
      });
    },
    show_add_modal() {
      this.add_visible = true;
    },
  },
};
</script>

<style scoped>
.add_button {
  align-self: center;
  margin-right: 50px;
}
.top_header {
  background-color: #f0f2f5;
  width: 100%;
  height: 80px;
  display: flex;
  direction: rtl;
}
</style>