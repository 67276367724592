<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div class="top_header">
      <div class="serach_body">
        <a-input-search
          class="input_body"
          placeholder="输入信息"
          enter-button="搜索"
          @search="onSearch"
        />
      </div>
    </div>
    <a-table
      :key="user_key"
      :columns="columns"
      :data-source="user_list"
      :pagination="false"
    >
    <div class="caozuo" slot-scope="text, record" slot="caozuo">
      <a-button
        class="edit_button"
        @click="edit_button(record.id)"
        size="small"
        type="primary"
      >
        编辑
      </a-button>
    </div>

      <a-switch
        slot-scope="text, record"
        slot="is_vip"
        @change="status_change(record)"
        :defaultChecked="text == 1 ? true : false"
        checked-children="开"
        un-checked-children="关"
        default-checked
      />
    </a-table>
    <a-pagination
      @change="user_page_change"
      :total="user_total"
      show-less-items
    />
    <a-modal
      title="会员编辑"
      :visible="add_visible"
      @ok="add_ok"
      @cancel="add_close"
      destroyOnClose
      :maskClosable="false"
      width="1100px"
    >
    <a-form-model
    
    
  >
    <a-form-model-item required label="输入会员天数">
      <a-input type="number" v-model="vip_time" />
    </a-form-model-item>
    
  </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { getUsers, showUsers, setUsers,setUsersVip } from "@/api";
const columns = [
  {
    dataIndex: "id",
    key: "id",
    align: "center",
    title: "id",
  },

  {
    title: "微信名称",
    align: "center",
    dataIndex: "nickname",
  },
  {
    title: "是否会员",
    align: "center",
    dataIndex: "is_vip",
    scopedSlots: { customRender: "is_vip" },
  },
  {
    title: "代理用户",
    align: "center",
    dataIndex: "agent",
  },
  {
    title: "vip结束时间",
    align: "center",
    dataIndex: "vip_time",
    scopedSlots: { customRender: "vip_time" },
    customRender: (text, row, index) => {
      var t = new Date(text*1000).toLocaleString(); 
    return t;
    }
             

  },
  {
    title: "操作",
    align: "center",
    dataIndex: "caozuo",
    scopedSlots: { customRender: "caozuo" },
  },
];

export default {

  data() {
    return {
      columns,
      user_list: [],
      user_page: 1,
      user_total: 0,
      user_key: 0,
      search_desc: "",
      item_data:{},
      add_visible:false,
      vip_time:0
    };
  },
  methods: {
    add_ok(){
      var param={
        id:this.item_data.id,
        vip_time:this.vip_time
      }
      setUsersVip(param).then(res=>{
        console.log(res);
        this.add_visible=false
      this.item_data={}
      this.vip_time=0
      })
    },
    add_close(){
      this.add_visible=false
      this.item_data={}
      this.vip_time=0
    },
    edit_button(id){
      console.log(id);
      showUsers(id).then(res=>{
        this.item_data=res.data.data
        this.add_visible=true

        console.log(res);
      })
    },

    onSearch(value) {
      this.user_page = 1;
      
      getUsers(this.user_page, value).then((res) => {
        this.user_list = res.data.data.data;
        this.user_total = res.data.data.total;
        this.user_key=this.user_key+1
      });
    },
    user_page_change(e) {
      this.user_page = e;
      getUsers(this.user_page,'').then((res) => {
        this.user_list = res.data.data.data;
        this.user_total = res.data.data.total;
        this.user_key = this.user_key + 1;
      });
    },
    status_change(e) {
      const param = {
        is_vip: e.is_vip == 1 ? 0 : 1,
      };
      setUsers(param, e.id).then((res) => {
        console.log(res);
      });
    },
  },
  mounted() {
    getUsers(this.user_page,'').then((res) => {
      this.user_list = res.data.data.data;
      this.user_total = res.data.data.total;
      this.user_key=this.user_key+1
    });
  },
};
</script>
<style scoped>
.input_body {
  margin: 0 auto;
}
.serach_body {
  text-align: center;
  align-self: center;
  width: 300px;
  background-color: white;
  display: flex;
  direction: ltr;
  margin: 0 auto;
}
.top_header {
  background-color: #f0f2f5;
  width: 100%;
  height: 80px;
  direction: rtl;
  display: flex;
}
.caozuo {
  display: flex;
}

</style>