<template>
  <div class="menu_top">
    <a-menu
      :default-selected-keys="['1']"
      :default-open-keys="['2']"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed"
    >
      <template v-for="item in list">
        <a-menu-item
          @click="ClickMenu(item)"
          v-if="!item.children"
          :key="item.name"
        >
          <a-icon :type="item.icon" />
          <span>{{ item.title }}</span>
        </a-menu-item>
        <!-- <a-sub-menu v-else :key="item.name" :menu-info="item" /> -->
        <a-sub-menu v-else :key="item.path" :menu-info="item">
          <span slot="title"
            ><a-icon :type="item.icon" /><span>{{ item.title }}</span></span
          >
          <a-menu-item
            @click="ClickMenu(value)"
            v-for="value in item.children"
            :key="value.name"
          >
            {{ value.title }}
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      collapsed: false,
      list: [
        {
          path: "/home",
          name: "home",
          title: "首页",
          icon:"home"
        },
        {
          path: "/moves",
          name: "moves",
          title: "视频管理",
          icon:'play-circle',
          children: [
            {
              path: "/moves/movelist",
              name: "movelist",
              title: "视频列表",
            }
            
          ]
        },
        {
          path: "/users",
          name: "users",
          title: "用户列表",
          icon:"user"
        },
        {
          path: "/agent",
          name: "agent",
          title: "代理列表",
          icon:"usergroup-add"
        },
        {
          path: "/agent_ref",
          name: "agent_ref",
          title: "代理提现列表",
          icon:"dropbox"
        },
        {
          path: "/orders",
          name: "orders",
          title: "订单列表",
          icon:"alipay"
        },
        {
          path: "/cates",
          name: "cates",
          title: "分类管理",
          icon:"ordered-list"
        },
        {
          path: "/stars",
          name: "stars",
          title: "演员管理",
          icon:"youtube"
        },
        {
          path: "/vipcate",
          name: "vipcate",
          title: "会员分类管理",
          icon:"alipay-circle"
        },
        {
          path: "/setting",
          name: "setting",
          title: "设置",
          icon:"setting"
        },
        {
          path: "/huifu",
          name: "huifu",
          title: "自动回复",
          icon:"setting"
        },
        {
          path: "/view",
          name: "view",
          title: "页面设置",
          icon:"layout"
        },
        
      ],
    };
  },
  methods: {
    toggleCollapsed() {
      this.collapsed = !this.collapsed;
    },
    ClickMenu(item) {
      console.log("====================================");
      console.log(item);
      console.log("====================================");
      this.$router.push(item.path);
    },
  },
};
</script>
  <style scoped>
.menu_top {
  display: flex;
}

.element.style {
  width: 100%;
}
</style>
  