<template>
    <div>
      <div class="top_header">
        <a-button class="add_button" @click="show_add_modal" type="primary"
          >添加</a-button
        >
      </div>
      <a-table
        :columns="columns"
        :data-source="list_data"
        :pagination="false"
      >
        <div class="caozuo" slot="caozuo" slot-scope="record">
          <a-button
            @click="show_edit_modal(record.id)"
            size="small"
            type="primary"
            style="margin-right: 30px"
          >
            编辑
          </a-button>
          <a-popconfirm
            title="确定删除？"
            ok-text="Yes"
            cancel-text="No"
            @confirm="delet_book_item(record.id)"
            @cancel="delet_cancel"
          >
            <a-button
              size="small"
              type="danger"
            >
              删除
            </a-button>
          </a-popconfirm>
        </div>
      </a-table>
      <a-pagination
      @change="page_change"
      :total="total"
      show-less-items
    />
      <a-modal
        title="添加"
        :visible="add_visible"
        @ok="add_ok"
        @cancel="add_can"
        :width="820"
      >
        <a-form-model :model="add_from">
          <a-form-model-item label="回复数字">
            <a-input style="width: 300px" v-model="add_from.huifu_num" />
          </a-form-model-item>
          <a-form-model-item label="电影id">
            <a-input style="width: 300px" v-model="add_from.film_id" />
          </a-form-model-item>
          
        </a-form-model>
      </a-modal>
      <a-modal
        title="编辑"
        :visible="edit_visible"
        @ok="edit_ok"
        @cancel="edit_can"
        :width="820"
      >
        <a-form-model :model="edit_from">
            <a-form-model-item label="回复数字">
                <a-input style="width: 300px" v-model="edit_from.huifu_num" />
              </a-form-model-item>
              <a-form-model-item label="电影id">
                <a-input style="width: 300px" v-model="edit_from.film_id" />
              </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </template>
  
  <script>
  import {
    getHuifu,
    getHuifuItem,
    addHuifu,
    deleteHuifu,
    updateHuifu,
  } from "@/api";
  const columns = [
    {
      dataIndex: "id",
      key: "id",
      align: "center",
      title: "id",
    },
   
    {
      title: "回复数字",
      align: "center",
      dataIndex: "huifu_num",
    },
    {
      title: "电影id",
      align: "center",
      dataIndex: "film_id",
    },
    {
      title: "电影名字",
      align: "center",
      dataIndex: "film.name",
    },
  
    {
      title: "操作",
      align: "center",
      scopedSlots: { customRender: "caozuo" },
    },
  ];
  export default {
    data() {
      return {
        columns,
        list_data: [],
        add_visible: false,
        add_from: {
          huifu_num: "",
          filim_id: "",
        },
        edit_from: {},
        edit_visible: false,
        page:1,
        total:0
      };
    },
    mounted() {
        getHuifu(this.page).then((res) => {
        this.list_data = res.data.data.data;
        this.total=res.data.data.total
      });
    },
    methods: {
        page_change(e){
            this.page=e
            getHuifu(this.page).then((res) => {
        this.list_data = res.data.data.data;
        this.total=res.data.data.total
      });
        },
      delet_book_item(id){
          console.log(id);
          deleteHuifu(id).then(res=>{
            getHuifu(this.page).then((res) => {
        this.list_data = res.data.data.data;
        this.total=res.data.data.total
      });
          })
      },
      delet_cancel(){},
      show_edit_modal(id) {
        getHuifuItem(id).then((res) => {
          this.edit_from = res.data.data;
          this.edit_visible = true;
          
        });
      },
      edit_can() {
        this.edit_visible = false;
        this.edit_from = {};
      },
      edit_ok() {
        updateHuifu(this.edit_from, this.edit_from.id).then((res) => {
            getHuifu(this.page).then((res) => {
            this.list_data = res.data.data.data;
            this.total=res.data.data.total
          });
          this.edit_visible=false
        });
      },
      add_can() {
        this.add_visible = false;
        this.add_from = {
          huifu_num: "",
          film_id: "",
        };
      },
      add_ok() {
        addHuifu(this.add_from).then((res) => {
            getHuifu(this.page).then((res) => {
            this.list_data = res.data.data.data;
            this.total=res.data.data.total
            this.add_visible = false;
            this.add_from = {
              name: "",
              price: "",
            };
          });
        });
      },
      show_add_modal() {
        this.add_visible = true;
      },
    },
  };
  </script>
  
  <style scoped>
  .add_button {
    align-self: center;
    margin-right: 50px;
  }
  .top_header {
    background-color: #f0f2f5;
    width: 100%;
    height: 80px;
    display: flex;
    direction: rtl;
  }
  </style>